<template>
<div class="mainform">
  <div class="form">
    <div class="header">基本信息</div>
    <el-form ref="form" :model="form" :rules="formRules" class="content bascform" :class="{disabled:formDisabled}" label-width="400px">
      <div v-if="fieldShow.companyName!==false" class="col col8">
        <el-form-item label="公司名称" prop="companyName">
            <el-input :disabled="true" v-model="form.companyName"></el-input>
        </el-form-item>
      </div>
      <div v-if="fieldShow.companyAccount!==false" class="col col8">
        <el-form-item label="公司账号" prop="companyAccount">
            <el-input :disabled="true" v-model="form.companyAccount"></el-input>
        </el-form-item>
      </div>
      <div v-if="fieldShow.contact!==false" class="col col8">
        <el-form-item label="联系人" prop="contact">
            <el-input :disabled="true" v-model="form.contact"></el-input>
            <el-button @click="changeContact" type="text" icon="el-icon-edit"></el-button>
        </el-form-item>
      </div>
      <div v-if="fieldShow.phone!==false" class="col col8">
        <el-form-item label="手机号" prop="phone">
            <el-input :disabled="true" v-model="form.phone"></el-input>
            <el-button @click="changePhone" type="text" icon="el-icon-edit"></el-button>
        </el-form-item>
      </div>
      <div v-if="fieldShow.mail!==false" class="col col8">
        <el-form-item label="邮箱" prop="mail">
            <el-input :disabled="true" v-model="form.mail"></el-input>
            <el-button @click="changeEmil" type="text" icon="el-icon-edit"></el-button>
        </el-form-item>
      </div>
      <div v-if="fieldShow.createTime!==false" class="col col8">
        <el-form-item label="注册日期" prop="createTime">
            <el-input :disabled="true" v-model="form.createTime"></el-input>
        </el-form-item>
      </div>
    </el-form>
    <div class="action">
      <el-button @click="changePassword" type="text" size="medium">修改密码</el-button>
    </div>
  </div>
  <dialog-box ref="dialogbox" :dialogShow="dialogShow" :form="form" componentName="ComAccountDialogBody"
                @handleClose='handleClose' :title="title" :fieldshow="fieldshow" :dialogtype="dialogtype"
                :buttonData="buttonData" @action="action" ></dialog-box>
</div>
</template>

<script>
import Component from '@/assets/js/components.js'
import { request } from '@/assets/js/http.js'
export default {
  name: 'ComAccount',
  components: Component.components,
  data () {
    return {
      formDisabled: true,
      form: {},
      dialogShow: false,
      dialogtype: 0,
      fieldShow: {},
      fieldshow: {},
      formRules: {},
      title: '',
      buttonData: [{ label: '取消', action: 'handleClose', size: 'mini' }, { label: '确认', action: 'action', size: 'mini', type: 'primary' }]
    }
  },
  created () {
    request('/api/supplier/basicData/companyAccount', 'get', {}).then((response) => {
      if (response.code === '200') {
        this.form = response.data
      }
    })
  },
  methods: {
    changeContact () {
      this.fieldshow = { phone: false, mail: false, verifyCode: false, newPassword: false, oldPassword: false, verifyPassword: false }
      this.dialogtype = 4
      this.title = '修改联系人'
      this.dialogShow = true
    },
    changePhone () {
      this.fieldshow = { contact: false, mail: false, newPassword: false, oldPassword: false, verifyPassword: false }
      this.dialogtype = 3
      this.title = '修改手机'
      this.dialogShow = true
    },
    changeEmil () {
      this.fieldshow = { contact: false, phone: false, newPassword: false, oldPassword: false, verifyPassword: false }
      this.dialogtype = 2
      this.title = '修改电子邮箱'
      this.dialogShow = true
    },
    changePassword () {
      this.fieldshow = { contact: false, phone: false, mail: false, verifyCode: false }
      this.dialogtype = 1
      this.title = '修改密码'
      this.dialogShow = true
    },
    handleClose () {
      this.dialogShow = false
    },
    async action () {
      const data = { ...this.$refs.dialogbox.$refs.content.formData }
      if (this.dialogtype === 1) {
        let pass = false
        this.$refs.dialogbox.$refs.content.$refs.form.validate((valid) => {
          pass = valid
        })
        if (!pass) {
          return false
        }
        delete data.verifyPassword
        request('/api/system/user/updatePassword', 'get', data).then((response) => {
          if (response.code === '200') {
            this.$message({
              message: '修改成功',
              type: 'success'
            })
          }
        })
      } else {
        let pass = true
        if (this.dialogtype === 2) {
          await request('/api/system/user/verifyMail', 'get', { mail: data.mail, verificationCode: data.verifyCode }).then((response) => {
            if (response.code !== '200') {
              pass = false
              this.$message({
                type: 'error',
                message: '验证码错误！'
              })
            }
          })
        }
        if (this.dialogtype === 3) {
          await request('/api/system/user/verifySms', 'get', { phone: data.phone, verificationCode: data.verifyCode }).then((response) => {
            if (response.code !== '200') {
              pass = false
              this.$message({
                type: 'error',
                message: '验证码错误！'
              })
            }
          })
        }
        if (pass) {
          data.supplierId = sessionStorage.getItem('enterpriseCode')
          request('/api/supplier/basicData/updateCompanyAccountById', 'get', data).then((response) => {
            if (response.code === '200') {
              this.$message({
                message: '修改成功',
                type: 'success'
              })
            }
          })
        }
      }
      this.dialogShow = false
    }
  }
}
</script>

<style scoped lang="scss">
  @import '@/assets/css/elForm.scss';
  .action{
    margin-bottom: 20px;
  }
  .mainform{
    .form{
        .el-form{
            :deep(.el-form-item){
                .el-form-item__content .el-input {
                    width: 450px;
                }
            }
        }
    }
  }

</style>
